<template>
	<div>
		<div class='popContainerM' v-if="navigationvalue">
			<el-row>
				<div @click="close" class="close">
					<p class="closeIcon">
						<i class="el-icon-circle-close"></i>
					</p>
					<p class="closeFont">close</p>
				</div>
			</el-row>
			<div class="popContainerMData">
				<div @click="navigationClick('/moveHomePage')" class="router-link">HOME</div>
				<div @click="show" class="router-link">ABOUT</div>
				<div id="jumpBox">
					<p v-if="isshow" @click="navigationClick('/moveAboutUs')" class="router-linkdata">Who we are</p>
					<p v-if="isshow" @click="navigationClick('/moveNews')" class="router-linkdata">What's on</p>
					<p v-if="isshow" @click="navigationClick('/movePresidentMessage')" class="router-linkdata">Chair's message</p>
					<p v-if="isshow" @click="navigationClick('/moveLeadership')" class="router-linkdata">Executives</p>
					<p v-if="isshow" @click="navigationClick('/moveValues')" class="router-linkdata">Values</p>
				</div>
				<div @click="navigationClick('/moveTheaters')" class="router-link">THEATRES</div>
				<div @click="navigationClick('/moveProductions')" class="router-link">PRODUCTIONS</div>
				<div @click="navigationClick('/movePrograms')" class="router-link">PROGRAMS</div>
				<div @click="navigationClick('/moveEducation')" class="router-link">EDUCATION</div>
				<div @click="navigationClick('/moveTicketing')" class="router-link">TICKETING</div>
				<div @click="navigationClick('/moveInternational')" class="router-link">INTERNATIONAL CLLABORATIONS</div>
				<div @click="navigationClick('/moveContact')" class="router-link">CONTACT</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default
	{
		inject: ["routerReload"],
		data()
		{
			return{
				// 导航值
				navigationvalue:false,
				isshow:false,
			}
		},
		props:
		{
			meauValue:Boolean
		},
		watch:
		{
			meauValue: 
			{
				handler(newVal, oldVal)
				{
					this.navigationvalue=newVal;
				},
				immediate: true,
				deep: true,
			}
		},
		methods:
		{
			// 关闭遮罩层
			close()
			{
				this.navigationvalue=false;
				this.isshow=false;
				this.$emit('meauFn',this.navigationvalue)
			},
			show()
			{
				this.isshow=true;
			},
			navigationClick(path)
			{
				this.$router.push(path)
				this.routerReload()
			},
		}
	}
</script>

<style scoped>
	.popContainerM {
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0, 0, 0, 0.7);
		z-index: 9999;
		padding-top: 50px;
		display: flex;
	}
	.popContainerMData{
		width: 88%;
		margin-left: 6%;
		align-self: center;
	}
	.router-link{
		color: white !important;
		font-size: 25px;
		line-height: 40px;
	}
	.router-linkdata{
		color: white;
		font-size: 25px;
		line-height: 40px;
	}
	.close{
		width: 100%;
		height: 50px;
		text-align: left;
		color: white;
		position: fixed;
		margin: -20px 0px 0px 30px;
	}
	.el-icon-circle-close{
		font-size: 25px;
		color: white;
	}
	.closeIcon{
		width: 40px;
		float: left;
		margin-top: 2px;
	}
	.closeFont{
		float: left;
		font-size: 25px;
		line-height: 30px;
	}
	@keyframes  ani{
	    0%{  opacity:0; }
	    100%{ opacity:1; }
	}
	
	.popContainerMData div:nth-child(1){
	   animation: ani 0.5s both;
	}
	.popContainerMData div:nth-child(2){
	   animation: ani 0.3s 0.3s both;
	}
	.popContainerMData div:nth-child(3){
	   animation: ani 0.3s 0.6s both;
	}
	.popContainerMData div:nth-child(4){
	   animation: ani 0.3s 0.9s both;
	}
	.popContainerMData div:nth-child(5){
	   animation: ani 0.3s 1.2s both;
	}
	.popContainerMData div:nth-child(6){
	   animation: ani 0.3s 1.5s both;
	}
	.popContainerMData div:nth-child(7){
	   animation: ani 0.3s 1.8s both;
	}
	.popContainerMData div:nth-child(8){
	   animation: ani 0.3s 2.1s both;
	}
	.popContainerMData div:nth-child(9){
	   animation: ani 0.3s 2.4s both;
	}
	.popContainerMData div:nth-child(10){
	   animation: ani 0.3s 2.7s both;
	}
	
	#jumpBox p:nth-child(1){
	   animation: ani 0.4s both;
	}
	#jumpBox p:nth-child(2){
	   animation: ani 0.4s 0.8s both;
	}
	#jumpBox p:nth-child(3){
	   animation: ani 0.4s 1.2s both;
	}
	#jumpBox p:nth-child(4){
	   animation: ani 0.4s 1.6s both;
	}
	#jumpBox p:nth-child(5){
	   animation: ani 0.4s 2s both;
	}
</style>