<template>
	<div class="bottom">
		<el-row>
			<el-row>
				<el-image class="poly" src="/moveIndex/poly.png"></el-image>
			</el-row>
			<el-row class="title">
				<a href="/#/moveAboutUs" :class="[isAboutShow==false?'moveBottomJump':'moveBottomJumpShow']">ABOUT</a>
			</el-row>
			<el-row class="teltitel">
				<span class="teltitelicon">
					<el-image class="about-img" src="/home/phone.png"></el-image>
				</span>
				<span class="teltiteldata">
					Tel:
				</span>
			</el-row>
			<el-row class="telmessage">
				010-65001188
			</el-row>
			<el-row class="teltitel">
				<span class="teltitelicon">
					<el-image class="about-img" src="/home/email.png"></el-image>
				</span>
				<span class="teltiteldata">
					E-mail:
				</span>
			</el-row>
			<el-row class="telmessage">
				info@polytheatre.com
			</el-row>
			<el-row class="teltitel">
				<span class="teltitelicon">
					<el-image class="about-img" src="/home/site.png"></el-image>
				</span>
				<span class="teltiteldata">
					Address:
				</span>
			</el-row>
			<el-row class="telmessage">
				NO. 14 Dongzhimen South Street,<br/>Dongcheng
				District,Beijing,China
			</el-row>
			<el-row class="design">
				Copyright &copy; 2019 Beijing Poly Theater Mangement Co., Ltd. Design by:BLPW
			</el-row>
		</el-row>
	</div>
</template>

<script>
	export default
	{
		data()
		{
			return{
				isAboutShow:false,
			}
		},
		created()
		{
			this.isAboutShow=false;
			if(this.$route.path=='/moveAboutUs')
			{
				this.isAboutShow=true;
			}
			else
			{
				this.isAboutShow=false;
			}
		},
	}
</script>

<style scoped>
	.bottom{
		width: 90%;
		background-color: #083191;
		padding-top: 50px;
		margin-top: 50px;
		color: white;
		padding-left: 5%;
		padding-right: 5%;
	}
	.poly{
		width: 117px;
		height: 53px;
		margin-bottom: 69px;
	}
	.title{
		margin-bottom: 80px;
		line-height: 40px;
		font-size: 36px;
		color: #FFFFFF;
	}
	.teltitel{
		font-size: 20px;
		margin-top: 75px;
		text-align: center;
	}
	.telmessage{
		font-size: 30px;
		margin-top: 20px;
		line-height: 30px;
		color: #FFFFFF;
	}
	.about-img{
		width: 30px;
		height: 30px;
	}
	.design{
		color: white;
		margin-top: 71px;
		margin-bottom: 70px;
		height: 17px;
		font-size: 18px;
		color: #FFFFFF;
	}
	.moveBottomJump{
		color: white;
	}
	.moveBottomJumpShow{
		color: white;
		border-bottom: solid 2px white;
	}
	.teltitelicon{
		height: 30px;
	}
	.teltiteldata{
		height: 30px;
		line-height:30px;
		font-size:30px;
	}
</style>