<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="newsHeadTitle" :headImg="newsHeadImg"></moveNavigation>
		
		<div class="newsListM">
			<!-- 新闻-数据 -->
			<el-row class="newsListMOne">
				<el-col :span="12" v-for="(item,id) in newList" :key="id">
					<div @click="newsDetails(item)" class="newsM-dataone">
						<img :src="newsNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="newsMdata-img"></img>
						<el-image class="newsMdata-coverageimg" src="/home/homeEventMcoverage.png"></el-image>
						<el-image class="newsMdata-logoimg" src="/home/logo.png"></el-image>
						<div class="newsMdata-titleMessage">
							<el-row class="newsMdata-title">{{item.title}}</el-row>
							<p class="newsMdata-date">
								<span>{{item.month}}</span>
								<span>{{item.time}},</span>
								<span>{{item.year}}</span>
							</p>
						</div>	
					</div>
				</el-col>
			</el-row>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'
	
// 头部组件
import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
// 头部导航
import moveNavigation from '@/components/move/moveNavigation.vue'
// 底部导航
import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
// 菜单导航
import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
export default
{
	data()
	{
		return{
			// 新闻头部导航标题
			newsHeadTitle:'WHAT`S ON',
			// 新闻头部导航图片
			newsHeadImg:'/index/list.png',
			// 导航值
			navigationvalue:false,
			
			newList:[],
			newsNotLoadImage: "/Mimage/335_384.jpg", //新闻图片未加载出来
		}
	},
	components:
	{
		moveHeadLogo,
		moveNavigation,
		moveBottomNavigation,
		moveMeauDialog,
	},
	created()
	{
		this.getNewsList()
	},
	methods:
	{
		newsDetails(item)
		{
			localStorage.setItem('Details',JSON.stringify(item));
			this.$router.push(
			{
				path:'/moveNoticesDetails',
				query:
				{
					item:item,
					headTitle:'WHAT`S ON',
					headImg:'/index/list.png',
				}
			})
		},
		
		// 获取NEWS新闻数据
		getNewsList()
		{
			selectContentListById(
			{
			  chnanelType: 2,
			  isIndex: 0,
			  moduleType: 'MODULE_WHAT'
			})
			.then((response)=>
			{
				this.newList=response.data.data;
				this.newList.map((v)=>
				{
					this.time(v)
				})
			})
		},
		
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		// 图片加载完成时触发
		onLoadImg(imgSrc) 
		{
			return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
		},
		// 时间
		time(v)
		{
			v.pulish=v.publish.split('-')
			if(v.pulish[1]=='01')
			{
				v.pulish[1]='JAN'
			}
			else if(v.pulish[1]=='02')
			{
				v.pulish[1]='FEB'
			}
			else if(v.pulish[1]=='03')
			{
				v.pulish[1]='MAR'
			}
			else if(v.pulish[1]=='04')
			{
				v.pulish[1]='APR'
			}
			else if(v.pulish[1]=='05')
			{
				v.pulish[1]='MAY'
			}
			else if(v.pulish[1]=='06')
			{
				v.pulish[1]='JUN'
			}
			else if(v.pulish[1]=='07')
			{
				v.pulish[1]='JUL'
			}
			else if(v.pulish[1]=='08')
			{
				v.pulish[1]='AUG'
			}
			else if(v.pulish[1]=='09')
			{
				v.pulish[1]='SEP'
			}
			else if(v.pulish[1]=='10')
			{
				v.pulish[1]='OCT'
			}
			else if(v.pulish[1]=='11')
			{
				v.pulish[1]='NOV'
			}
			else if(v.pulish[1]=='12')
			{
				v.pulish[1]='DEC'
			}
			v.year=v.pulish[0];
			v.month=v.pulish[1];
			v.time=v.pulish[2];
		},
	}
}
</script>

<style scoped>
	@import '../../assets/MoveNewsBox.css';
	.newsListM{
		width: 100%;
		margin-top: 60px;
	}
</style>