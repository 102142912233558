<template>
	<div class="navigation">
		<el-image src="/index/background.png" class="navigation-img"></el-image>
		
		<div class="title">
			<span class="title-span">{{headTitle}}</span>
		</div>
		
		<!-- 画布 -->
		<div class="coverage">
			<el-image class="coverage-img" src="/home/coverage.png"></el-image>
			<el-image class="coverage-newsimg" :src="headImg"></el-image>
		</div>
	</div>
</template>

<script>
	export default
	{
		data()
		{
			return{
			}
		},
		props:
		{
			headTitle:String,
			headImg:String
		},
	}
</script>

<style scoped>
	.navigation{
		width: 100%;
		position: relative;
	}
	.navigation-img{
		width: 100%;
		height: 196px;
	}
	.title{
		width: 713px;
		height: 62px;
		position: absolute;
		bottom: 90px;
		left: 36px;
		text-align: left;
		color: white;
		border-bottom: solid 1px #f5f5f5;
	}
	.title-span{
		font-size: 36px;
		color: #FFFFFF;
		line-height: 50px;
		padding-bottom: 17px;
		border-bottom: solid 5px white;
	}
	
	.coverage{
		width: 100%;
		height: 0px;
		position: relative;
		margin-bottom: 50px;
	}
	.coverage .coverage-img{
		z-index: 9;
		position: absolute;
		width: 100%;
		height: 30px;
		left: 0px;
		bottom: 0px;
	}
	.coverage .coverage-newsimg{
		width: 40px;
		height: 40px;
		left: 355px;
		bottom: -20px;
		z-index: 99;
		position: absolute;
	}
</style>