<template>
	<div>
		<el-row class="index-head">
			<el-col :span="5">
				<el-col :span="7" :offset="2">
					<div class="meau-imgboxM" @click="navigation">
						<el-image class="meau-imgM" src="/home/meau.png"></el-image>
					</div>
				</el-col>
				<el-col :span="13" :offset="1">
					<div class="meau-titleM" @click="navigation">MENU</div>
				</el-col>
			</el-col>
			
			<el-col :span="15">
				<div class="logo-boxM" @click="logoJump">
					<el-image class="logo-imgM" src="/home/headlogo.png"></el-image>
				</div>
			</el-col>
			
			<el-col :span="4">
				<div class="cn-boxM" @click="toChinese">
					<el-image class="cn-imgM" src="/home/CN.png"></el-image>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>

export default 
{
	data()
	{
		return{
			navigationvalue:false
		}
	},
	created()
	{
		this.$emit('navigation',this.navigationvalue)
	},
	methods:
	{
		// 点击导航
		navigation()
		{
			this.navigationvalue=true
			this.$emit('navigation',this.navigationvalue)
		},
		toChinese()
		{
			console.log(window.location.href)
			window.location.href = 'https://www.polytheatre.com/'
			window.location.replace('https://www.polytheatre.com/')
		},
		logoJump()
		{
			if(this.$route.path=='/moveHomePage')
			{
				console.log('为当前路由')
			}
			else
			{
				this.$router.push('/moveHomePage')
			}
		}
	}
}
</script>

<style scoped>
	/* 封装css */
	@import '../../assets/MoveHeadLogo.css';
</style>